import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { contentBox, primaryButton } from "../../styles";
import { Row, Col, Select, Table, Divider, DatePicker, Button, Spin, Form, Input } from "antd";
import ReactJson from 'react-json-view'
import { toJS } from "mobx";
import { DownloadOutlined } from "@ant-design/icons";
import InvestigationStore from "../../stores/investigation.store";
import DeviceStore from "../../stores/device.store";
import DeviceLogStore from "../../stores/deviceLog.store";


function ExportHistory() {
    const historyStore = useContext(DeviceLogStore);
    const investigationStore = useContext(InvestigationStore);
    const deviceStore = useContext(DeviceStore);

    useEffect(async () => {
        await investigationStore.getInvestigations();
        await deviceStore.getAllDevices();
    }, []);


    const columns = [
        {
            title: "Timestamp (UTC)",
            dataIndex: "epochDate",
            key: "epochDate",
            render: (text, record) => {
                const date = new Date(record.epochDate);
                return (
                    <div>
                        {date.toISOString()}
                    </div>
                )
            }
        },
        {
            title: "Timestamp (Local)",
            dataIndex: "epochDate",
            key: "epochDate",
            render: (text, record) => {
                const date = new Date(record.epochDate);
                return (
                    <div>
                        {date.toLocaleString()}
                    </div>
                )
            }
        },
        {
            title: "Message Type",
            dataIndex: "incomingType",
            key: "incomingType",
        },
        {
            title: "Ip Address",
            dataIndex: "ipAddress",
            key: "ipAddress",
        }
    ];
    return (
        <div
            style={contentBox({marginTop: "1px"})}
        >
            <div>
                <div
                    style={contentBox({ marginTop: "1px" })}
                >
                    Device:
                    <Divider type="vertical" />
                    <Select
                        showSearch
                        style={{ width: 400 }}
                        placeholder="Select A Device"
                        allowClear
                        onChange={(value) => {
                            const device = deviceStore.getDeviceBySerial(value);
                            historyStore.updateDevice(device);
                        }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={deviceStore.devices.map((device) => {
                            return {
                                label: device.serial,
                                value: device.serial
                            }
                        })}
                    >
                    </Select>
                    <Divider type="vertical" />
                    Date (Local):
                    <Divider type="vertical" />
                    <DatePicker.RangePicker
                        disabled={historyStore.device == null}
                        onChange={(value) => {
                            const start = value[0].startOf('day').unix();
                            const end = value[1].endOf('day').unix();
                            historyStore.updateTimeRange([start, end])
                            historyStore.UpdateExportHistory();
                        }}
                        style={{ width: 400 }}

                        dateRender={(current) => {
                            const style = {};
                            if (historyStore.logHistory?.includes(current.startOf('day').format("YYYY-MM-DD").toString())) {
                                style.border = '1px solid black';
                                style.backgroundColor = "#80808075";
                                style.color = "black"
                            }
                            return (
                              <div className="ant-picker-cell-inner" style={style}>
                                {current.date()}
                              </div>
                            );
                          }}
                    />
                    <Divider type="vertical" />
                    Message Type:
                    <Divider type="vertical" />
                    <Select
                        showSearch
                        style={{ width: 400 }}
                        placeholder="Message Type"
                        allowClear
                        onChange={(value) => {
                            historyStore.setType(value);
                        }}
                        disabled={historyStore.device == null}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={historyStore.messageTypes.map((type) => {
                            return {
                                label: type,
                                value: type
                            }
                        })}
                    >
                    </Select>
                    <Divider type="vertical" />
                    <Input
                        allowClear
                        onChange={(value) => {
                            if (value === '') {
                                value = null;
                            }
                            console.log(value.target.value);
                            historyStore.setSearch(value.target.value);
                        }}
                    >
                    </Input>

                </div>

                <Divider type="vertical" />
                {historyStore.spinning && <Spin />}

            </div>
            <div>
                <Row>
                    <Col span={11}
                        style={contentBox(
                            //{maxHeight: "70vh"}
                            {background: "#262626"}
                        )}
                    >
                        {historyStore.ResultSelectedEvent &&
                            <ReactJson src={toJS(historyStore.ResultSelectedEvent)} theme={"monokai"} />
                        }
                    </Col>
                    <Col span={1} />
                    <Col span={11}
                        style={contentBox()}
                    >
                        {
                            historyStore.exportHistory.length > 0 ? (
                                <Table
                                    columns={columns}
                                    dataSource={toJS(historyStore.displayHistory)}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => { historyStore.selectEvent(record) }, // click row
                                            style: { background: historyStore.selectedEvent?.id == record.id ? "#00ddff" : "" }
                                        };
                                    }}
                                    pagination={{
                                        position: ['topRight', 'bottomRight'],
                                        defaultPageSize: 10,
                                        pageSizeOptions: [10, 20, 50, 100],
                                    }}
                                    size="medium"
                                    footer={() => {
                                        return (
                                            <div>
                                                <span style={{ marginRight: 10, }}>
                                                    {`Total: ${historyStore.displayHistory.length}`}
                                                </span>
                                                <Button
                                                    style={primaryButton()}
                                                    onClick={() => {
                                                        historyStore.downloadHistory();
                                                    }}
                                                    icon={<DownloadOutlined />}
                                                >

                                                </Button>
                                            </div>
                                        )
                                    }}
                                />) : (
                                <div>
                                    {'Select a Device and Date to show Device Logs'}

                                </div>
                            )}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default observer(ExportHistory);